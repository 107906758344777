import { Component } from '@angular/core';

@Component({
  selector: 'app-building-intelligence-service',
  templateUrl: './building-intelligence-service.component.html',
  styleUrls: ['./building-intelligence-service.component.scss']
})
export class BuildingIntelligenceServiceComponent {

}
