<section class="our-approach-area pb-70">
    <div class="container">
        <div class="section-title">
            <span>Our Approach</span>
            <h2>Enhancing Your Cyber Defenses with Expert Support</h2>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="approach-img">
                    <img src="assets/img/fortcess_cyber_defense.png" alt="Cyber Shields Approach">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="approach-content">
                    <h3>Delivering cutting-edge technology and expertise to fortify your cyber defenses against evolving threats.</h3>

                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <div class="single-approach">
                                <h3>Trusted Partner</h3>
                                <p>We establish long-term partnerships to understand and address your security needs, ensuring a resilient cyber posture.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="single-approach">
                                <h3>Product Security</h3>
                                <p>Our comprehensive product security assessments safeguard your products from design to deployment against cyber threats.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="single-approach">
                                <h3>System Security</h3>
                                <p>We fortify your system architecture, employing advanced measures to detect and mitigate vulnerabilities.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="single-approach">
                                <h3>Operational Security</h3>
                                <p>Our operational security solutions ensure seamless, secure operations, safeguarding your business continuity.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
