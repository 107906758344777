<!-- Start Footer Area -->
<footer class="footer-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo"><img src="assets/img/cs_logo2_trans.png" alt="Image"></a>
                    
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Address</h3>

                    <ul class="address">
                        <li class="location">
                            <i class="bx bxs-location-plus"></i>
                            Gambrills, MD, USA
                        </li>
                        <li>
                            <i class="bx bxs-envelope"></i>
                            <a href="mailto:info@cybershields.tech">info@cybershields.tech</a>
                        </li>
                        <li>
                            <i class="bx bxs-phone-call"></i>
                            <a href="tel:+1-4434946680">+1 (443) 494-6680</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Solutions</h3>

                    <ul class="import-link">
                        <li><a routerLink="/cyber-planning-service">Cyber Planning</a></li>
                        <li><a routerLink="/web-app-dev-service">Web Application Development</a></li>
                        <li><a routerLink="/network-design-service">Computer Network Infrastructure Design</a></li>
                        <li><a routerLink="/building-intelligence-service">Building Intelligence Products</a></li>
                      
                    </ul>
                </div>
            </div>

         
        </div>
    </div>
</footer>
<!-- End Footer Area -->

<!-- Start Copy Right Area -->
<div class="copy-right-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <p>© Cyber Shields, LLC <a href="/" target="_blank"></a></p>
            </div>

            <!-- <div class="col-lg-6 col-md-6">
                <ul class="footer-menu">
                    <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                    <li><a routerLink="/terms-conditions">Terms & Conditions</a></li>
                </ul>
            </div> -->
        </div>
    </div>
</div>
<!-- End Copy Right Area -->