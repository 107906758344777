<app-navbar-style-three></app-navbar-style-three>

<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Product and Services Details</h2>
            <ul>
                <li><a routerLink="/"><i class="bx bx-home"></i> Home</a></li>
                <li class="active">Building Enhancement Solutions</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area pt-100 pb-70">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-12">
                <div class="services-details-content">
                    <div class="services-content">
                        <h2>Cyber Shields: Advancing Your Building's Intelligence</h2>
                        <p>
                          At Cyber Shields, we are devoted to creating products that significantly enhance the functionality and intelligence of your existing buildings. Our innovative solutions interface with a broad spectrum of sensors providing homeowners and business proprietors with insightful data regarding the efficiency and health of their environment. Our pioneering Power Over Ethernet (PoE) system is a testament to our commitment to energy efficiency and adaptability, making it an ideal choice for a myriad of applications.
                        </p>
                        <div class="services-details-one">
                            <img src="assets/img/building-enhancement.png" alt="Building Enhancement">
                        </div>
                    </div>

                    <div class="services-content">
                        <h3>Our Building Enhancement Solutions</h3>
                        <p>
                          Our solutions are engineered to provide a seamless integration with your existing infrastructure, offering a range of services including:
                        </p>
                        <ul>
                          <li>Advanced Sensor Integration</li>
                          <li>Power Over Ethernet (PoE) Systems</li>
                          <li>Energy Efficiency Analysis</li>
                          <li>Real-time Monitoring and Analytics</li>
                          <li>Customizable Insights Dashboard</li>
                        </ul>
                    </div>

                    <div class="services-content">
                        <h3>Power Over Ethernet (PoE) Systems</h3>
                        <p>
                          Our PoE systems are designed for scalability and energy efficiency, providing a robust platform that can adapt to the evolving needs of your home or business. With the ability to power a wide range of devices while minimizing energy consumption, our PoE solutions represent a significant stride towards smarter, greener buildings.
                        </p>
                    </div>

                    <div class="services-content">
                        <h3>Why Choose Cyber Shields?</h3>
                        <p>
                          Partner with Cyber Shields to leverage our technical acumen and innovative solutions that propel your building into the future. Our dedicated team of experts is committed to delivering products that not only meet but exceed your expectations, ensuring a smarter, safer, and more efficient environment.
                        </p>
                    </div>
                  
                </div>
            </div>

            <div class="row justify-content-center">
                <div class="col-lg-8 col-md-12">
                    <div class="services-contact mb-30">
                        <h3>Contact us to elevate your building's intelligence</h3>
                        <a routerLink="/contact" class="default-btn"><span>Contact Us</span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Details Area -->
