import { Component, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-hometwo-features',
  templateUrl: './hometwo-features.component.html',
  styleUrls: ['./hometwo-features.component.scss']
})
export class HometwoFeaturesComponent implements OnInit {

  constructor(private route: ActivatedRoute, private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.route.fragment.subscribe(fragment => {
      if (fragment) {
        const element = this.el.nativeElement.querySelector('#' + fragment);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    });
  }

}
