import { Component } from '@angular/core';

@Component({
  selector: 'app-web-app-dev-service',
  templateUrl: './web-app-dev-service.component.html',
  styleUrls: ['./web-app-dev-service.component.scss']
})
export class WebAppDevServiceComponent {

}
