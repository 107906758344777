<section class="services-area pt-100 pb-70" id="overview">
    <div class="container">
        <div class="section-title">
            <span>Integrated Technology Solutions</span>
            <h2>Empowering Your Organization Through Cybersecurity, Web Development, and Network Infrastructure</h2>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <a routerLink="/cyber-planning-service">
                            <img src="assets/img/fortcess_cyber_defense.png" alt="Cyber Planning Image" width="100%" height="100%">
                        </a>
                    </div>
                    <div class="services-content">
                        <h3><a routerLink="/cyber-planning-service">Cyber Planning</a></h3>
                        <div class="content">
                            <p>Strategic cyber planning to fortify your organization against digital threats.</p>
                            <a routerLink="/cyber-planning-service" class="read-more">Read More <i class="flaticon-right-arrow"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <a routerLink="/web-app-dev-service">
                            <img src="assets/img/webapp.png" alt="Web Application Development Image">
                        </a>
                    </div>
                    <div class="services-content">
                        <h3><a routerLink="/web-app-dev-service">Web Application Development</a></h3>
                        <p>Creating robust, scalable, and secure web applications tailored to your needs.</p>
                        <a routerLink="/web-app-dev-service" class="read-more">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <a routerLink="/network-design-service">
                            <img src="assets/img/network.png" alt="Computer Network Infrastructure Design Image">
                        </a>
                    </div>
                    <div class="services-content">
                        <h3><a routerLink="/network-design-service">Computer Network Infrastructure Design</a></h3>
                        <p>Designing reliable and secure network infrastructure to support your operations.</p>
                        <a routerLink="/network-design-service" class="read-more">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <a routerLink="/building-intelligence-service">
                            <img src="assets/img/building.png" alt="Building Intelligence Products Image">
                        </a>
                    </div>
                    <div class="services-content">
                        <h3><a routerLink="/building-intelligence-service">Building Intelligence Products</a></h3>
                        <p>Developing smart products to enhance building intelligence and efficiency.</p>
                        <a routerLink="/building-intelligence-service" class="read-more">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
