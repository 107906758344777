<app-navbar-style-three></app-navbar-style-three>

<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Blog Details</h2>
            <ul>
                <li><a routerLink="/"><i class="bx bx-home"></i> Home</a></li>
                <li class="active">Blog Details</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Blog Details Area -->
<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-content">
                    <div class="blog-details-img">
                        <img src="assets/img/blog-details/blog-details-img.jpg" alt="Image">
                    </div>

                    <div class="blog-top-content">
                        <div class="news-content">
                            <ul class="admin">
                                <li><a routerLink="/blog-grid"><i class="bx bx-user-circle"></i> Posted by: Carl Bradshaw</a></li>
                                <li><a routerLink="/blog-grid"><i class="bx bx-comment"></i> No comments</a></li>
                                <li class="float"><i class="bx bx-calendar-alt"></i> September 20, 2023</li>
                            </ul>
                            <h3>The essentials of cybersecurity solutions</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempo.</p>
                            <p>Praesent dapibus, neque id cursus faucibus, tortor neque egestas auguae, eu vulputate magna eros eu Aliquam erat volutpat. Nam dui mi, tincidunt quis, accumsan porttitor, facilisis luctus, metus.</p>
                        </div>

                        <blockquote>
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temporincididunt ut labore dolore magna aliqua.”</p>
                            <i class="bx bxs-quote-alt-left"></i>
                        </blockquote>

                        <div class="news-content-2">
                            <p>Phasellus ultrices nulla quis nibh. Quisque a lectus. Donec consectetuer ligula vulputate sem tristique Nam nulla quam, gravida non, commodo a, sodales sit amet, nisi nulla quis nibh. Quisque a lectus. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. malesuada erat ut turpis. Suspendisse urna nibh, viverra non semper suscipit ultrices nulla quis nibh.</p>
                        </div>

                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="single-blog-post-img">
                                    <a routerLink="/blog-details">
                                        <img src="assets/img/blog-details/blog-1.jpg" alt="Image">
                                    </a>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="single-blog-post-img">
                                    <a routerLink="/blog-details">
                                        <img src="assets/img/blog-details/blog-2.jpg" alt="Image">
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="news-content-3">
                            <p>Phasellus ultrices nulla quis nibh. Quisque a lectus. Donec consectetuer ligula vulputate sem tristique Nam nulla quam, gravida non, commodo a, sodales sit amet, nisi nulla quis nibh. Quisque a lectus. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. malesuada erat ut turpis. Suspendisse urna nibh, viverra non semper suscipit ultrices nulla quis nibh.</p>
                        </div>

                        <div class="tag-list">
                            <span>Tags:</span>

                            <ul>
                                <li><a routerLink="/blog-grid">Cybersecurity</a></li>
                                <li><a routerLink="/blog-grid">Seaacurity</a></li>
                                <li><a routerLink="/blog-grid">Hackers</a></li>
                                <li><a routerLink="/blog-grid">Cybercrime</a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="comments">
                        <h3>Comments (2)</h3>

                        <ul>
                            <li>
                                <img src="assets/img/blog-details/comments-img-1.jpg" alt="Image">
                                <h3>Juanita Jones</h3>
                                <span>Monday, September 20, 2023</span>
                                <p>Lorem ipsum dolora sit amet, consectetur adipiscing elit sed do eiusmod tempor incdidunt labore et dolore magna aliqua. Veniam quis nostrud exercitation ullaco laboris</p>
                                <a routerLink="/blog-details">Reply</a>
                            </li>
                            <li>
                                <img src="assets/img/blog-details/comments-img-2.jpg" alt="Image">
                                <h3>Ward F. Nelson</h3>
                                <span>Monday, September 20, 2023</span>
                                <p>Lorem ipsum dolora sit amet, consectetur adipiscing elit sed do eiusmod tempor incdidunt labore et dolore magna aliqua. Veniam quis nostrud exercitation ullaco laboris</p>
                                <a routerLink="/blog-details">Reply</a>
                            </li>
                        </ul>
                    </div>

                    <div class="leave-reply">
                        <h3>Leave a reply</h3>
                        <p>Your email address will not be published. Required fields are marked<span class="star">*</span></p>

                        <form>
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <label>Name<span class="star">*</span></label>
                                        <input type="text" name="name" id="name" class="form-control">
                                    </div>
                                </div>
    
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <label>Email address<span class="star">*</span></label>
                                        <input type="email" name="email" id="email" class="form-control">
                                    </div>
                                </div>
    
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label>Your website</label>
                                        <input type="text" name="your-website-link" id="your-website-link" class="form-control">
                                    </div>
                                </div>
    
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label>Comment</label>
                                        <textarea name="message" class="form-control" id="message" rows="8"></textarea>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="form-group">
                                        <input type="checkbox" id="chb2">
                                        <span>Save my name, email, and website in this browser for the next time I comment.</span>
                                    </div>
                                </div>
    
                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn page-btn"><span>Post a comment</span></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="widget-sidebar">
                    <div class="sidebar-widget search">
                        <form class="search-form">
                            <input class="form-control" name="search" placeholder="Search here" type="text">
                            <button class="search-button" type="submit"><i class="bx bx-search"></i></button>
                        </form>	
                    </div>

                    <div class="sidebar-widget recent-post">
                        <h3 class="widget-title">Recent Post</h3>
                        
                        <ul>
                            <li>
                                <a routerLink="/blog-details">
                                    The essentiaaals of cybersecurity solutions
                                    <img src="assets/img/blog-details/recent-post-1.jpg" alt="Image">
                                </a>
                                <span>September 19,2023</span>
                            </li>
                            <li>
                                <a routerLink="/blog-details">
                                    Top 10 cybersecurity tips for working place
                                    <img src="assets/img/blog-details/recent-post-2.jpg" alt="Image">
                                </a>
                                <span>September 18,2023</span>
                            </li>
                            <li>
                                <a routerLink="/blog-details">
                                    Don’t forget caybersecurity on your computer
                                    <img src="assets/img/blog-details/recent-post-3.jpg" alt="Image">
                                </a>
                                <span>September 09,2023</span>
                            </li>
                        </ul>
                    </div>

                    <div class="sidebar-widget categories">
                        <h3>Categories</h3>

                        <ul>
                            <li><a routerLink="/blog-right-sidebar">Cyber Security</a></li>
                            <li><a routerLink="/blog-right-sidebar">Network Security</a></li>
                            <li><a routerLink="/blog-right-sidebar">Threat Hunter</a></li>
                            <li><a routerLink="/blog-right-sidebar">Incident Responder</a></li>
                            <li><a routerLink="/blog-right-sidebar">Secure Managed IT</a></li>
                            <li><a routerLink="/blog-right-sidebar">Server Monitoring</a></li>
                            <li><a routerLink="/blog-right-sidebar">Penetration Testing</a></li>
                        </ul>
                    </div>

                    <div class="sidebar-widget categories">
                        <h3>Archives</h3>

                        <ul>
                            <li><a routerLink="/blog-right-sidebar">August 2023</a></li>
                            <li><a routerLink="/blog-right-sidebar">June 2023</a></li>
                            <li><a routerLink="/blog-right-sidebar">April 2023</a></li>
                            <li><a routerLink="/blog-right-sidebar">January 2023</a></li>
                            <li><a routerLink="/blog-right-sidebar">December 2023</a></li>
                            <li><a routerLink="/blog-right-sidebar">November 2023</a></li>
                        </ul>
                    </div>

                    <div class="sidebar-widget tags mb-0">
                        <h3>Tags</h3>

                        <ul>
                            <li><a routerLink="/blog-right-sidebar">Cyber</a></li>
                            <li><a routerLink="/blog-right-sidebar">Security</a></li>
                            <li><a routerLink="/blog-right-sidebar">Network</a></li>
                            <li><a routerLink="/blog-right-sidebar">Responder</a></li>
                            <li><a routerLink="/blog-right-sidebar">Secure</a></li>
                            <li><a routerLink="/blog-right-sidebar">Monitoring</a></li>
                            <li><a routerLink="/blog-right-sidebar">Penetration</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Blog Details Area -->