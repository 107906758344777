<app-navbar-style-three></app-navbar-style-three>

<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Services Details</h2>
            <ul>
                <li><a routerLink="/"><i class="bx bx-home"></i> Home</a></li>
                <li class="active">Offensive and Defensive Cyber Planning</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area pt-100 pb-70">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-12">
                <div class="services-details-content">
                    <div class="services-content">
                        <h2>Cyber Shields: Your Partner in Cyber Operations</h2>
                        <p>
                          At Cyber Shields, we specialize in both Offensive (CNE) and Defensive (CND) cyber planning operations, providing tailored solutions to a diverse clientele including government entities and private sector companies. Our cyber operations strategy is designed to robustly protect your digital assets while proactively identifying and mitigating cyber threats.
                        </p>
                        <div class="services-details-one">
                            <img src="assets/img/partnership.png" alt="Image">
                        </div>
                    </div>

                    <div class="services-content">
                        <h3>Our Cyber Planning Services</h3>
                        <p>
                          We offer a comprehensive range of cyber planning services to fortify your organization against evolving cyber threats. Our services include but are not limited to:
                        </p>
                        <ul>
                          <li>Cyber Threat Intelligence and Analysis</li>
                          <li>Strategic Cyber Planning</li>
                          <li>Cyber Risk Assessment and Management</li>
                          <li>Offensive Cyber Operations (OCO)</li>
                          <li>Defensive Cyber Operations (DCO)</li>
                        </ul>
                    </div>

                    <div class="services-content">
                        <h3>Why Choose Cyber Shields?</h3>
                        <p>
                          Our team of seasoned cyber experts leverages cutting-edge technology to deliver actionable insights and robust cyber solutions, ensuring optimal security posture for your organization. Partner with Cyber Shields to navigate the complex cyber landscape with confidence.
                        </p>
                    </div>
                  
                </div>
            </div>

            
            <div class="row justify-content-center">
                <div class="col-lg-8 col-md-12">
                    <div class="services-contact mb-30">
                        <h3>Contact us to do your new projects</h3>
                        <a routerLink="/contact" class="default-btn"><span>Contact Us</span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
      </section>
<!-- End Services Details Area -->