import { Component } from '@angular/core';

@Component({
  selector: 'app-cyber-planning-service',
  templateUrl: './cyber-planning-service.component.html',
  styleUrls: ['./cyber-planning-service.component.scss']
})
export class CyberPlanningServiceComponent {

}
