<app-navbar-style-three></app-navbar-style-three>

<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Services Details</h2>
            <ul>
                <li><a routerLink="/"><i class="bx bx-home"></i> Home</a></li>
                <li class="active">Web Application Development</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area pt-100 pb-70">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-12">
                <div class="services-details-content">
                    <div class="services-content">
                        <h2>Cyber Shields: Your Web Application Development Partner</h2>
                        <p>
                          At Cyber Shields, we excel in creating sophisticated web applications utilizing both front end and back end technologies. Our adept team employs the latest technologies and adheres to industry-leading security standards to deliver robust, secure, and user-friendly web solutions tailored to meet the unique needs of our clients.
                        </p>
                        <div class="services-details-one">
                            <img src="assets/img/web-development.png" alt="Web Development">
                        </div>
                    </div>

                    <div class="services-content">
                        <h3>Our Web Application Development Services</h3>
                        <p>
                          We provide a comprehensive suite of web application development services encompassing all stages from conceptualization, design, development, to deployment and maintenance. Our offerings include but are not limited to:
                        </p>
                        <ul>
                          <li>Custom Web Application Development</li>
                          <li>Front End Development</li>
                          <li>Back End Development</li>
                          <li>Database Management</li>
                          <li>Security Implementation</li>
                        </ul>
                    </div>

                    <div class="services-content">
                        <h3>Why Choose Cyber Shields?</h3>
                        <p>
                          Our dedicated team of developers and cyber security experts ensure that your web applications are not only functionally rich but also secure from potential cyber threats. Partner with Cyber Shields to leverage our technical expertise and secure your digital presence.
                        </p>
                    </div>
                  
                </div>
            </div>

            <div class="row justify-content-center">
                <div class="col-lg-8 col-md-12">
                    <div class="services-contact mb-30">
                        <h3>Contact us to kickstart your web application projects</h3>
                        <a routerLink="/contact" class="default-btn"><span>Contact Us</span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Details Area -->
