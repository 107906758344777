<section class="feature-area pb-70"  >
    <div class="container" id="overview">
        <div class="row">
            <div class="col-lg-4 col-sm-6" >
                <div class="single-feature overly-one">
                    <div class="overly-two">
                        <div class="title">
                            <i class="flaticon-testing"></i>
                            <h3>Penetration Testing</h3>
                        </div>
                        <p>Our expert team conducts thorough penetration testing to identify vulnerabilities and strengthen your network against potential threats.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-feature overly-one">
                    <div class="overly-two">
                        <div class="title">
                            <i class="flaticon-cybercrime"></i>
                            <h3>Cyber Threat Intelligence</h3>
                        </div>
                        <p>We delve into the cyber threat landscape to provide actionable intelligence, enabling proactive defense and informed decision-making.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="single-feature overly-one">
                    <div class="overly-two">
                        <div class="title">
                            <i class="flaticon-cyber-security"></i>
                            <h3>Innovative Development</h3>
                        </div>
                        <p>Our adept team transforms ideas into reality by crafting top-tier web applications and products, ensuring they're not only functional and user-friendly, but also secure and scalable.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
