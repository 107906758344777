<app-navbar-style-three></app-navbar-style-three>

<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Our Team</h2>
            <ul>
                <li><a routerLink="/"><i class="bx bx-home"></i> Home</a></li>
                <li class="active">Team</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Team Area -->
<section class="team-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <!-- Derrick Edwards -->
            <div class="col-lg-4 col-sm-6">
                <div class="single-team">
                    <img src="assets/img/team/derrick-edwards.jpg" alt="Derrick Edwards">
                    <div class="team-content">
                        <h3>Derrick Edwards</h3>
                        <span>President and CEO</span>
                        <p>Cyber Shields, LLC</p>
                    </div>
                </div>
            </div>

            <!-- Yolanda Edwards -->
            <div class="col-lg-4 col-sm-6">
                <div class="single-team">
                    <img src="assets/img/team/yolanda-edwards.jpg" alt="Yolanda Edwards">
                    <div class="team-content">
                        <h3>Yolanda Edwards</h3>
                        <span>Vice President</span>
                        <p>Cyber Shields, LLC</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Team Area -->
