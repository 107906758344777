<app-navbar-style-two></app-navbar-style-two>

<app-hometwo-banner></app-hometwo-banner>

<app-hometwo-features></app-hometwo-features>

<!-- <app-hometwo-approach></app-hometwo-approach> -->

<!-- <app-our-challenges></app-our-challenges> -->

<app-hometwo-services></app-hometwo-services>

<!-- <app-hometwo-pricing></app-hometwo-pricing> -->

<!-- <app-funfacts></app-funfacts> -->

<!-- <section class="team-area pt-100 pb-70">
    <app-team></app-team>
</section> -->

<app-get-in-touch></app-get-in-touch>

<!-- <app-hometwo-blog></app-hometwo-blog> -->