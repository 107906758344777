<app-navbar-style-three></app-navbar-style-three>

<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Services Details</h2>
            <ul>
                <li><a routerLink="/"><i class="bx bx-home"></i> Home</a></li>
                <li class="active">Secure Network Design & Installation</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area pt-100 pb-70">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-12">
                <div class="services-details-content">
                    <div class="services-content">
                        <h2>Cyber Shields: Your Partner in Secure Network Solutions</h2>
                        <p>
                          At Cyber Shields, we specialize in designing and installing secure computer networks that stand resilient against evolving cyber threats. Utilizing the latest technologies and adhering to security standards set by the National Institute of Standards and Technology at the U.S. Department of Commerce, we ensure a fortified network infrastructure. Our approach incorporates a zero trust framework, ensuring that every element within the network is authenticated and verified, promoting a secure system from the ground up.
                        </p>
                        <div class="services-details-one">
                            <img src="assets/img/secure_network.png" alt="Network Security">
                        </div>
                    </div>

                    <div class="services-content">
                        <h3>Our Network Solutions</h3>
                        <p>
                          Our comprehensive suite of network solutions is tailored to meet the unique needs of our clients. Our services include, but are not limited to:
                        </p>
                        <ul>
                          <li>Secure Network Design & Implementation</li>
                          <li>Zero Trust Network Architecture</li>
                          <li>Network Security Assessment & Enhancement</li>
                          <li>Network Data Recovery Solutions</li>
                        </ul>
                    </div>

                    <div class="services-content">
                        <h3>Network Data Recoverability</h3>
                        <p>
                          Our network data recovery solutions ensure business continuity even in the face of unexpected data loss scenarios. We implement robust backup and recovery strategies to safeguard your critical data and ensure a swift recovery with minimal downtime.
                        </p>
                    </div>

                    <div class="services-content">
                        <h3>Why Choose Cyber Shields?</h3>
                        <p>
                          Partner with Cyber Shields to navigate the complex landscape of network security with confidence. Our seasoned team of network and security experts leverages cutting-edge technology to deliver secure, reliable, and scalable network solutions, ensuring an optimal security posture for your organization.
                        </p>
                    </div>
                  
                </div>
            </div>

            <div class="row justify-content-center">
                <div class="col-lg-8 col-md-12">
                    <div class="services-contact mb-30">
                        <h3>Contact us to fortify your network infrastructure</h3>
                        <a routerLink="/contact" class="default-btn"><span>Contact Us</span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Details Area -->
