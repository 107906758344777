import { Component } from '@angular/core';

@Component({
  selector: 'app-network-design-service',
  templateUrl: './network-design-service.component.html',
  styleUrls: ['./network-design-service.component.scss']
})
export class NetworkDesignServiceComponent {

}
